<script setup lang="ts">
export type BadgeColor = 'primary' | 'teal' | 'blue' | 'purple' | 'pink' | 'red' | 'orange' | 'gray';

const colors = {
  primary: {
    intense: 'bg-primary-500 text-white',
    default: 'bg-primary-100 text-primary-700',
  },
  teal: {
    intense: 'bg-teal-500 text-white',
    default: 'bg-teal-100 text-teal-700',
  },
  blue: {
    intense: 'bg-blue-500 text-white',
    default: 'bg-blue-100 text-blue-700',
  },
  purple: {
    intense: 'bg-purple-500 text-white',
    default: 'bg-purple-100 text-purple-700',
  },
  pink: {
    intense: 'bg-pink-500 text-white',
    default: 'bg-pink-100 text-pink-700',
  },
  red: {
    intense: 'bg-red-500 text-white',
    default: 'bg-red-100 text-red-700',
  },
  orange: {
    intense: 'bg-orange-500 text-white',
    default: 'bg-orange-100 text-orange-700',
  },
  gray: {
    intense: 'bg-gray-500 text-white',
    default: 'bg-gray-100 text-gray-700',
  },
};

const props = withDefaults(
  defineProps<{
    color: BadgeColor;
    size?: 'sm' | 'md';
    intense?: boolean;
  }>(),
  {
    color: 'blue',
    size: 'md',
    intense: false,
  },
);
const getColor = computed(() => {
  if (props.intense) {
    return colors[props.color].intense;
  }
  return colors[props.color].default;
});

const getSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'px-1.5 py-1 text-[8px]';
    case 'md':
      return 'px-2 py-1 text-xs';
    default:
      return 'px-2 py-1 text-xs';
  }
});
</script>

<template>
  <span :class="[getColor, getSize]" class="inline-flex items-center rounded-md font-medium">
    <slot />
  </span>
</template>
